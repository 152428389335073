<template>
  <div drop-list class="coupon-drop-list-box" :class="[convertDirection, className, {mounted, animated, 'on': showList},theme]" :style="elStyle">
    <ul ref="listBox" class="list-box">
      <li v-for="(item, index) in (showList || [])" :key="`list-${index}${uid}`"
        :class="{'active': value === (item.value || item), unusable: getState(item) !== 'good'}" @mousedown.left="_clickListHandler(item)" :data-state="getState(item)">
        <div class="discount-left">
          <em class="discount-title">{{ getTitle(item) }}</em>
          <p class="discount-description">{{ getDesc(item) }}</p>
        </div>
        <div v-if="item !== ''" class="discount-right">
          <p class="discount-price expired" v-if="getState(item) === 'expired'">기간 만료</p>
          <p class="discount-price" v-else-if="getState(item) === 'disabled'">사용 불가</p>
          <p class="discount-price" v-else>{{ getDiscountText(item) }}</p>
          <p class="discount-end">{{ getEndTimeText(item) }}</p>
        </div>
      </li>
      <li v-show="!showList.length" class="none-data">{{ $t('input.noList') }}</li>
    </ul>
  </div>
</template>

<script>
import { commaDecimal } from '@shared/utils/numberUtils';
import DropListBox from '@/views/components/common/input/DropListBox.vue';

export default {
  extends: DropListBox,
  props: ['coupons'],
  computed: {
    cp() {
      return /** @type{LessonCoupon[]} */this.coupons || [];
    },
    couponsById() {
      const cs = {};
      this.cp.forEach(c => cs[c.coupon.couponId] = c);
      return /** @type{Record<string, LessonCoupon>} */cs;
    },
  },
  methods: {
    getTitle(couponId) {
      return this.couponsById?.[couponId]?.coupon?.title ?? '선택 안함';
    },
    getDesc(couponId) {
      return this.couponsById?.[couponId]?.coupon?.description ?? '';
    },
    getDateExpire(timestamp) {
      if (!timestamp) return false;
      return (Date.now() - timestamp) > 0;
    },
    getState(couponId) {
      // 선택안함도 사용할 수 있어야 함
      if (couponId === '') return 'good';
      const coupon = this.couponsById?.[couponId];
      const disabled = !coupon?.canUse ?? false;
      if (disabled) return 'disabled';
      const isExpired = this.getDateExpire(coupon?.coupon?.endDateTime);
      if (isExpired) return 'expired';
      return 'good';
    },
    getDiscountText(couponId) {
      const discountPrice = this.couponsById?.[couponId]?.affectedAmount ?? 0;
      return `- ${commaDecimal(discountPrice)} UP`;
    },
    getEndTimeText(couponId) {
      const endTime = this.couponsById?.[couponId]?.coupon?.endDateTime;
      if (endTime === null) return '기한 없음';
      return ['~', this.$date(endTime, 'datetimeAlt')].join(' ');
    },
    _clickListHandler(couponId) {
      console.log('CouponDropListBox._clickhandler', typeof couponId, couponId);
      if (this.getState(couponId) !== 'good' && this.couponId !== '') return;
      console.log('CouponDropListBox -> clickable');
      this.clickListHandler(couponId);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[drop-list].coupon-drop-list-box li {.flex;.flex-jc(space-between);.pt(8);.pb(8);
  .discount-title {.c(#191919);}
  .discount-title, .discount-price {.fs(14);}
  .discount-description, .discount-end {.fs(12)}
  .discount-right {text-align: right;}
  .discount-price {.c(red)}
  &.unusable {
    .discount-title, .discount-price, .discount-description, .discount-end {.c(#ddd)}
  }
}
.coupon-drop-list-box {
  &[drop-list] .list-box li {.lh(20);}
}
</style>
