<template>
  <div drop-select ref="dropSelect" class="coupon" :class="[`no-drag ${direction}`, { focus, error: error && !Boolean(dropListBox), readonly, disabled, open: Boolean(dropListBox), yet: !value, }, theme]">
    <label @click="toggleOpen">
      <coupon-drop-input :class="['no-drag', variant && `btn-${variant}`]" :value="currentCoupon"/>
    </label>
    <input :name="name" type="hidden" :value="value"/>
    <p class="angle" @click="toggleOpen">
      <SvgAngle/>
    </p>
  </div>
</template>

<script>
import DropSelect from '@/views/components/common/input/DropSelect.vue';
import Vue from 'vue';
import CouponDropListBox from './CouponDropListBox.vue';
import CouponDropInput from '@/views/components/coaching/modal-coupon/CouponDropInput.vue';
import _ from 'lodash';

export default {
  components: { CouponDropInput },
  name: 'CouponDropSelect',
  extends: DropSelect,
  props: ['coupons'],
  computed: {
    currentCoupon() {
      return this.coupons.find(c => c.coupon?.couponId === this.value);
    }
  },
  methods: {
    // DropSelect.vue 의 createDropList에서 컴포넌트만 바꾼 것
    createDropList() {
      if (this.readonly) return;
      this.removeDropList();
      this.dropListBox = new (Vue.extend(CouponDropListBox))({
          parent: this.$root,
          propsData: {
            callComponent: this,
            className: this.variant,
            list: this.listCreated,
            value: this.value,
            direction: this.direction,
            theme: this.theme,
            coupons: this.coupons, // 쿠폰 표기를 위해 별도로 추가한 prop
          }
        }
      ).$mount();

      this.focus = true;
    },
    update() {
      let val = '';
      const list = this.listCreated;

      if (list) {
        val = _.find(list, item => (item.value || item) === this.value);
        if (!val && !this.placeholder) val = this.getDefaultValue();
      }

      this.change(val);
    },
    getDefaultValue() {
      // 일단은 기본값 = 선택 안함으로 설정
      return '';
    }
  }
};
</script>
