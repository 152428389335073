<template>
  <!--  <component :is="tag" type="text" :class="['no-drag', variant && `btn-${variant}`]" :value="selected && ( selected.label || selected )" :placeholder="placeholder" readonly="readonly"/>-->
  <div coupon-drop-input v-if="selected" class="selected">
    {{ title }}
  </div>
  <div coupon-drop-input v-else>
    선택 안함
  </div>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    v() {
      return /** @type{LessonCoupon} */ this.value;
    },
    couponId() {
      return this.v?.coupon?.couponId ?? '';
    },
    selected() {
      return this.couponId !== '';
    },
    title() {
      return this.v?.coupon?.title ?? '';
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coupon-drop-input] {.wh(100%);.lh(45);.pl(12); .pointer;}
</style>
