<template>
  <!-- 리뉴얼 전 이름: CoachCouponUseModal -->
  <modal modal-coupon @close="$emit('reject')">
    <template #title>
      <h1>수업 신청</h1>
    </template>
    <p class="main-hint">선택한 수강권을 구매하고,<br /> 지금 수업을 시작하시겠습니까?</p>
    <p class="control-hint coupon-drop-select">사용 가능 쿠폰 <em>{{ affordableCouponAmount }}</em> 개 / 전체 <em>{{ couponAmount }}</em> 개</p>
    <coupon-drop-select :list="couponIdList" v-model="couponId" :coupons="coupons" :affordableCouponsId="affordableCouponsId" />
    <p class="control-hint">최종 구매 가격</p>
    <point-icon shape="gray" :value="finalPrice" class="final-price" />
    <color-button type="dark" class="button-coupon-confirm" @click="onConfirm">네, 구매하고 수업을 시작하겠습니다</color-button>
  </modal>
</template>
<script>
import Modal from '@shared/components/common/Modal.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import PointIcon from '@/views/components/coaching/PointIcon.vue';
import CouponDropSelect from '@/views/components/coaching/modal-coupon/CouponDropSelect.vue';

export default {
  components: { CouponDropSelect, ColorButton, PointIcon, Modal },
  props: {
    options: {
      type: Object,
      default: () => ({
        bundleSize: 1,
        lessonProductId: '',
        defaultCouponId: '',
        originalPrice: 0,
      }),
    },
  },
  computed: {
    couponList() {
      return /** @type{LessonCoupon[]} */ this.coupons || [];
    },
    sortedCouponList() {
      return /** @type{LessonCoupon[]} */this.couponList.slice().sort((a, b) => {
        // 시간: 오름차순(먼저 종료되는 쿠폰이 우선 와야 함)
        /** @param {LessonCoupon} c */
        const getEndDateTime = c => c?.coupon?.endDateTime;
        const isInfinite = c => !getEndDateTime(c);
        if (isInfinite(a)) return 1;
        if (isInfinite(b)) return -1;
        return getEndDateTime(a) - getEndDateTime(b);
      }).sort((a, b) => {
        // 가격: 내림차순(비싼 쿠폰이 우선 와야 함)
        /** @param {LessonCoupon} c */
        const getPrice = c => c?.affectedAmount ?? 0;
        return getPrice(b) - getPrice(a);
      }).sort((a, b) => {
        // 사용 가능한 쿠폰이 우선 와야 함
        /** @param {LessonCoupon} c */
        const getDisabledSort = c => (c?.canUse ? 1 : 0);
        return getDisabledSort(b) - getDisabledSort(a);
      });
    },
    affordableCouponsId() {
      return this.sortedCouponList.filter(c => {
        // const isPriceOK = c?.affectedAmount < this.options?.originalPrice;
        const checkDateExpire = coupon => {
          if (!coupon?.coupon?.endDateTime) return false;
          return Date.now() - (coupon?.coupon?.endDateTime ?? 0) > 0;
        };
        // const isTimeOK = !checkDateExpire(c);
        // return isPriceOK && isTimeOK && c?.canUse;
        return !checkDateExpire(c) && c?.canUse;
      }).map(c => {
        const coupon = /** @type{LessonCoupon} */c;
        return coupon?.coupon?.couponId;
      });
    },
    couponIdList() {
      return ['', ...this.sortedCouponList.map(c => c.coupon.couponId)];
    },
    couponAmount() {
      return this.coupons?.length ?? 0;
    },
    affordableCouponAmount() {
      return this.affordableCouponsId?.length ?? 0;
    },
    coupon() {
      return /** @type{LessonCoupon} */ this.couponList.find(c => c?.coupon?.couponId === this.couponId) || {};
    },
    finalPrice() {
      return (this.options?.originalPrice ?? 0) - (this.coupon?.affectedAmount ?? 0);
    },
  },
  data: () => ({
    coupons: /** @type{LessonCoupon[]} */ [],
    couponId: '',
  }),
  async beforeMount() {
    this.couponId = this.options?.defaultCouponId;
    this.coupons = await this.$services.coaching.getLessonCoupons({ bundleSize: this.options?.bundleSize, lessonProductId: this.options?.lessonProductId });
  },
  methods: {
    onConfirm() {
      this.$emit('resolve', this.coupon);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[modal-coupon] {
  .final-price {.wh(100%, 58);.flex;.flex-jc(center);.flex-ai(center);.mb(45);}
  .button-coupon-confirm {.w(100%)}
  .scroll-area {.mb(20)}
  .panel .header h1 {text-align: center;.fs(20);.mb(18);}
  [drop-select].coupon {.mb(20)}
  .main-hint {text-align: center;.fs(14);.mb(40);}
  .control-hint {.mb(8);.fs(14);}
  .control-hint.coupon-drop-select {.c(#787781) }
  .control-hint.coupon-drop-select > em {.c(#191919) }
}
</style>
