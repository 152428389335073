<template>
  <div drop-select ref="dropSelect" :class="[`no-drag ${direction}`, { focus, error: error && !Boolean(dropListBox), readonly, disabled, open: Boolean(dropListBox), yet: !value, }, theme]">
    <label @click="toggleOpen">
      <input type="text" :class="['no-drag', variant && `btn-${variant}`]" :value="selected && ( selected.label || selected )" :placeholder="placeholder" readonly="readonly" />
    </label>
    <input :name="name" type="hidden" :value="value" />
    <p class="angle">
      <SvgAngle />
    </p>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import SvgAngle from '@shared/graphics/svg-angle.vue';
import DropListBox from '@/views/components/common/input/DropListBox.vue';

export default {
  name: 'DropSelect',
  components: { SvgAngle },
  props: {
    value: { default: null },
    list: { type: Array, default: null },
    split: { type: String, default: null },
    obj: { default: null },
    placeholder: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    useBlank: { type: Boolean, default: false },
    variant: { type: String, default: null },
    artificial: { type: Boolean, default: true },
    direction: { type: String, default: 'down' },
    name: { type: String },
    error: { type: Boolean, default: false },
    theme: { type: String, default: 'white' },
  },
  data() {
    return {
      dropListBox: null,
      selected: null,
      focus: false,
    };
  },
  mounted() {
    this.eventListenerHandler();
    this.update(true);
  },
  destroyed() {
    this.removeDropList();
    this.eventListenerHandler(true);
  },
  watch: {
    list: 'update',
    obj: 'update',
    value: 'update',
  },
  computed: {
    listCreated() {
      let list;

      if (this.obj) {
        list = Object.entries(this.obj).map(([key, value]) => ({ label: value, value: key }));
      } else {
        list = this.split ? this.split.split(/[ ]*,[ ]*/) : this.list;
      }

      if (this.useBlank && list) {
        list = [...[{ value: null, label: this.placeholder || '-' }], ...list];
      }

      return list;
    },
  },
  methods: {
    toggleOpen() {
      if (this.disabled) return;

      if (this.dropListBox) this.removeDropList();
      else this.createDropList();
    },
    createDropList() {
      if (this.readonly) return;
      this.removeDropList();
      this.dropListBox = new (Vue.extend(DropListBox))({
          parent: this.$root,
          propsData: {
            callComponent: this,
            className: this.variant,
            list: this.listCreated,
            value: this.value,
            direction: this.direction,
            theme: this.theme,
          },
        }).$mount();

      this.focus = true;
    },
    async removeDropList() {
      if (!this.dropListBox) return;

      this.dropListBox.$destroy();
      this.dropListBox = null;
      this.focus = false;
    },
    clickOutSideHandler(e) {
      const { target } = e;
      if (!(this.$el === target || this.$el.contains(target))) {
        this.removeDropList();
      }
    },
    outSideScrollHandler(e) {
      const { target } = e;

      if (this.dropListBox && !this.dropListBox.$el.contains(target)) {
        this.removeDropList();
      }
    },
    eventListenerHandler(destroy = false) {
      if (destroy) {
        document.body.removeEventListener('click', this.clickOutSideHandler);
        window.removeEventListener('resize', this.removeDropList);
        document.removeEventListener('scroll', this.outSideScrollHandler, true);
      } else {
        document.body.addEventListener('click', this.clickOutSideHandler);
        window.addEventListener('resize', this.removeDropList);
        document.addEventListener('scroll', this.outSideScrollHandler, true);
      }
    },
    change(val) {
      this.removeDropList();
      const v = !val || val.value === undefined ? this.value : val.value;
      if (this.artificial) this.$emit('select', v);
      if (this.selected === val || (this.selected && this.selected?.value !== undefined && this.selected?.value === val?.value)) return;
      this.selected = val;
      this.$emit('input', val?.value || val);
      if (this.artificial) this.$emit('change', v);
    },
    update() {
      let val = '';
      const list = this.listCreated;

      if (list) {
        val = _.find(list, item => (item.value || item) === this.value);
        if (!val && !this.placeholder) [val] = list;
      }

      this.change(val);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

.input-group > .input-group-prepend > [drop-select] > .btn { border-top-right-radius: 0; border-bottom-right-radius: 0;}
.input-group > .input-group-append > [drop-select] > .btn { border-top-left-radius: 0; border-bottom-left-radius: 0;}

[drop-select] { .crop; .h(50); .-box; cursor: pointer; .rel; .bgc(white); .br(12); .-a(@c-base-gray); .pointer;
  > .angle { .wh(32); .abs; .rt(10, 50%); .z(1); .bgc(@c-base-gray;); .br(8); .t-yc; .flex-center;
    svg { .wh(12); stroke: @c-title-black; transition: transform 0.3s;}
  }
  &.focus { .-a(@c-base-gray); }
  &.open {
    &.down { .br-t(12); .-b(transparent); }
    &.up { .br-b(12); .-t(transparent); }
  }
  &.error {
    &::before { .cnt; .abs; .lb(0, 0); .z(2); .w(100%); .-b(#f90); }
  }
  input[type='text'] { .rel; .z(2); .wh(100%); .pr(31); .tl; .fs(14, 50px); .regular; .c(#787781); .ellipsis(1); .bgc(transparent); .pointer; }

  &.open {
    input[type='text'] { .br-t(12); .-b; }
    > .angle svg { .t-r(180deg); }
  }
  &.readonly { .bgc(#eee);
    input[type='text'] { pointer-events: none;cursor: default;}
  }
  &.product {
    input[type='text'] { .fs(14, 34); background: #2a2a2a; color: #fff; }
  }

  &.black { .bgc(rgba(255, 255, 255, 0.04)); .-a;
    > .angle { .bgc(transparent);
      svg { stroke: rgba(255, 255, 255, .5);}
    }
  }
}

.requested [drop-select].error {
  .-a(#f00) !important;
}

</style>
